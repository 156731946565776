import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import MuiLink from '@material-ui/core/Link';

import { useStyles } from '../styles/contact.styles';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { handleObserver } from '../utils/observer';
import logoOKE from '../images/icons/logoOKE.svg';
import logoOKEBlack from '../images/icons/logoOKEBlack.svg';
import ButtonLoader from '../components/ButtonLoader/ButtonLoader';
import {
    aboutUsTextOneENOne,
    aboutUsTextOneENTwo,
    aboutUsTextOneENThree,
    aboutUsTextTwoPLOne,
    aboutUsTextTwoPLTwo,
    aboutUsTextTwoENOne,
    aboutUsTextTwoENTwo,
    aboutUsTextOnePLOne,
    aboutUsTextOnePLTwo,
    aboutUsTextOnePLThree,
} from '../utils/static';

const Contact = ({ isEnglish }) => {
    const classes = useStyles();
    const [isVisible, setVisible] = useState(false);
    const refContact = useRef(null);
    const [{ email, message }, setMessageInfo] = useState({
        email: '',
        message: '',
    });

    const aboutUsTextOneWithHardSpaceOne = isEnglish
        ? aboutUsTextOneENOne
        : aboutUsTextOnePLOne;
    const aboutUsTextOneWithHardSpaceTwo = isEnglish
        ? aboutUsTextOneENTwo
        : aboutUsTextOnePLTwo;
    const aboutUsTextOneWithHardSpaceThree = isEnglish
        ? aboutUsTextOneENThree
        : aboutUsTextOnePLThree;

    const aboutUsTextTwoWithHardSpaceOne = isEnglish
        ? aboutUsTextTwoENOne
        : aboutUsTextTwoPLOne;
    const aboutUsTextTwoWithHardSpaceTwo = isEnglish
        ? aboutUsTextTwoENTwo
        : aboutUsTextTwoPLTwo;

    const handleChange = ({ target: { name, value } }) => {
        setMessageInfo(c => ({ ...c, [name]: value }));
    };

    useEffect(() => {
        const currentRef = refContact.current;
        handleObserver(currentRef, setVisible);
    }, []);
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg },
        });
        if (ok) {
            setMessageInfo({ email: '', message: '' });
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        let successMessage = isEnglish
            ? 'Message has been sent'
            : 'Wiadomość została wysłana';
        setServerState({ submitting: true });
        axios({
            method: 'post',
            url: 'https://getform.io/f/e0eef002-195a-4a1f-88d4-b7b7dd71b62d',
            data: new FormData(form),
        })
            .then(r => {
                handleServerResponse(true, successMessage, form);
            })
            .catch(r => {
                handleServerResponse(false, 'Oops!', form);
            });
    };
    return (
        <Layout>
            <SEO title="Kontakt" />
            <Box className={classes.gif} id="kontakt">
                <div ref={refContact}>
                    <Box className={classes.root}>
                        <Grid
                            container
                            justify="space-between"
                            className={classes.container}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className={
                                    isVisible
                                        ? classes.gridAnimation
                                        : classes.hiddenForm
                                }
                            >
                                <p className={classes.contactHeader}>
                                    {isEnglish ? 'CONTACT US' : 'KONTAKT'}
                                </p>
                                <form
                                    className={classes.form}
                                    onSubmit={handleOnSubmit}
                                    action="https://getform.io/f/e0eef002-195a-4a1f-88d4-b7b7dd71b62d"
                                    method="POST"
                                >
                                    <TextField
                                        required
                                        fullWidth
                                        variant="outlined"
                                        value={email}
                                        margin="normal"
                                        type="email"
                                        placeholder={
                                            isEnglish
                                                ? 'Your e-mail'
                                                : 'Twój adres e-mail'
                                        }
                                        name="email"
                                        onChange={handleChange}
                                        onFocus={() =>
                                            setServerState({
                                                ...serverState,
                                                status: null,
                                            })
                                        }
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        value={message}
                                        margin="normal"
                                        placeholder={
                                            isEnglish
                                                ? 'Your message'
                                                : 'Twoja wiadomość...'
                                        }
                                        name="message"
                                        rows={10}
                                        onChange={handleChange}
                                        onFocus={() =>
                                            setServerState({
                                                ...serverState,
                                                status: null,
                                            })
                                        }
                                    />
                                    <div
                                        className={classes.formButtonAndMessage}
                                    >
                                        {serverState.status && (
                                            <p
                                                className={
                                                    !serverState.status.ok
                                                        ? classes.errorMessage
                                                        : classes.successMessage
                                                }
                                            >
                                                {serverState.status.msg}
                                            </p>
                                        )}
                                        {!serverState.status && (
                                            <Button
                                                type="submit"
                                                className={classes.button}
                                            >
                                                {serverState.submitting ? (
                                                    <ButtonLoader />
                                                ) : isEnglish ? (
                                                    'SEND'
                                                ) : (
                                                    'WYŚLIJ'
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                </form>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className={
                                    isVisible
                                        ? classes.gridContainer
                                        : classes.hiddenText
                                }
                            >
                                <Box className={classes.descriptionBox}>
                                    <p className={classes.contactHeader}>
                                        {isEnglish ? 'ABOUT US' : 'O NAS'}
                                    </p>
                                    <Typography variant="body2" gutterBottom>
                                        {aboutUsTextOneWithHardSpaceOne}
                                        <MuiLink
                                            href="https://www.oke.pl"
                                            target="_blank"
                                            className={classes.okeLink}
                                        >
                                            OKE Poland
                                        </MuiLink>
                                        {aboutUsTextOneWithHardSpaceTwo}
                                        <MuiLink
                                            href="https://www.oke.pl"
                                            target="_blank"
                                            className={classes.okeLink}
                                        >
                                            OKE
                                        </MuiLink>
                                        {aboutUsTextOneWithHardSpaceThree}
                                    </Typography>
                                    <Box mb={3} />
                                    <Typography variant="body2">
                                        {aboutUsTextTwoWithHardSpaceOne}
                                        <MuiLink
                                            href="https://www.oke.pl"
                                            target="_blank"
                                            className={classes.okeLink}
                                        >
                                            OKE
                                        </MuiLink>
                                        {aboutUsTextTwoWithHardSpaceTwo}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Hidden only={['sm', 'xs']}>
                            <Box className={classes.footerContainer}>
                                <Grid
                                    container
                                    className={classes.footer}
                                    justify="space-around"
                                    alignItems="center"
                                >
                                    <Grid
                                        item
                                        container
                                        justify="space-between"
                                        xs={9}
                                    >
                                        <Grid
                                            item
                                            xs
                                            className={
                                                classes.footerItemWrapper
                                            }
                                        >
                                            <Box>
                                                OKE Poland Sp. z o.o.
                                                <br />
                                                {`ul. Heweliusza 11, 80${'\u2011'}890${'\u00a0'}Gdańsk`}
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            className={
                                                classes.footerItemWrapper
                                            }
                                        >
                                            <Box>
                                                <a href="tel:+48 58 321 72 46" className={classes.desktopLink}>tel. +48 58 321 72 46</a>
                                                <br />
                                                <a className={classes.desktopLink} target="_blank" href="https://www.oke.pl">www.oke.pl</a>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            className={
                                                classes.footerItemWrapper
                                            }
                                        >
                                            <Box>
                                            <a className={classes.desktopLink} href="mailto:info@civileo.pl">info@civileo.pl</a>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} className={classes.link}>
                                        <MuiLink
                                            href="https://www.oke.pl"
                                            target="_blank"
                                        >
                                            <img
                                                className={classes.okeLogo}
                                                src={logoOKEBlack}
                                                alt="Logo OKE"
                                            />
                                        </MuiLink>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Hidden>
                        <Hidden only={['md', 'lg', 'xl']}>
                            <Box mt={5} mb={5} className={classes.mobileFooter}>
                                OKE Poland Sp. z o.o.
                                <br />
                                ul. Heweliusza 11, 80-890 Gdańsk
                            </Box>
                            <Box mb={5}>
                            <a  className={classes.mobileFooter} href="tel:+48 58 321 72 46"
                            >
                                tel. +48 58 321 72 46</a>
                                <br />
                                <a  className={classes.mobileFooter} target="_blank" href="https://www.oke.pl">www.oke.pl</a>

                            </Box>
                            <Box mb={5} >
                            <a  className={classes.mobileFooter} href="mailto:info@civileo.pl">info@civileo.pl</a>
                                
                            </Box>
                            <MuiLink
                                href="https://www.oke.pl"
                                className={classes.linkLogo}
                            >
                                <img
                                    src={logoOKE}
                                    alt="Logo OKE"
                                    className={classes.logoOke}
                                />
                            </MuiLink>
                        </Hidden>
                    </Box>
                </div>
            </Box>
        </Layout>
    );
};
export default Contact;
