import React from 'react';
import styles from './ButtonLoader.module.css';

const ButtonLoader = () => {
    return (
        <div class={styles.ldsRing}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default ButtonLoader;
